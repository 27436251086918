import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import kartbakgrund from '../images/karta_karta.jpg';
import Vespa1 from '../images/karta_vespa1.png';
import Vespa2 from '../images/karta_vespa2.png';
import Target from '../images/karta_target.png';
import Vespa3 from '../images/karta_vespa3.png';
import { TweenMax, TimelineMax, Linear } from 'gsap';
import { CustomWiggle } from "../js/CustomWiggle";

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
    };

    this.Vespa1 = React.createRef();
    this.Vespa1img = React.createRef();
    this.Vespa2 = React.createRef();
    this.Vespa2img = React.createRef();
    this.Vespa3 = React.createRef();
    this.Vespa3img = React.createRef();
    this.Target = React.createRef();
    this.Hornstull = React.createRef();

    this.animSequence = null;
  }

  componentDidMount() {
    this.animSequence = new TimelineMax({ paused: true, repeat: -1, delay: 0.3, repeatDelay: 2 });

    this.setState(() => ({
      hasMounted: true,
    }));
  }

  componentWillUnmount() {
    TweenMax.killTweensOf(this.Vespa1img.current);
    TweenMax.killTweensOf(this.Vespa2img.current);
    TweenMax.killTweensOf(this.Vespa3img.current);
    TweenMax.killTweensOf(this.Target.current);
    TweenMax.killTweensOf(this.Vespa1.current);
    TweenMax.killTweensOf(this.Vespa2.current);
    TweenMax.killTweensOf(this.Vespa3.current);
    TweenMax.killTweensOf(this.Hornstull.current);
  }

  EnterAnimation = () => {
    TweenMax.to(this.Vespa1img.current, 0.1, { y: -5, repeat: -1, yoyo: true });
    TweenMax.to(this.Vespa2img.current, 0.1, { y: -5, repeat: -1, yoyo: true });
    TweenMax.to(this.Vespa3img.current, 0.1, { y: -5, repeat: -1, yoyo: true });

    this.animSequence.set(this.Vespa1img.current, { alpha: 0 });
    this.animSequence.set(this.Vespa2img.current, { alpha: 0 });
    this.animSequence.set(this.Vespa3img.current, { alpha: 0 });

    // Target
    TweenMax.to(this.Target.current, 0.5, {scale: 0.55, yoyo: true, repeat: -1, transformOrigin: "center", ease: Linear.easeNone });

    // Vespa 1
    this.animSequence.add(TweenMax.to(this.Vespa1.current, 0, { x: 853, y: 62 }));
    this.animSequence.add(TweenMax.fromTo(this.Vespa1img.current, 0.5, { scale: 0, alpha: 0 }, { scaleX: 0.5, scaleY: -0.5, alpha: 1, ease: Linear.easeNone }));
    this.animSequence.add(TweenMax.to(this.Vespa1.current, 2, { delay: 1, x: 900, y: 217, ease: Linear.easeNone}));

    // Vespa 2
    this.animSequence.add(TweenMax.to(this.Vespa1img.current, 0, { alpha: 0 }));
    this.animSequence.add(TweenMax.to(this.Vespa2img.current, 0, { alpha: 1 }));
    this.animSequence.add(TweenMax.fromTo(this.Vespa2.current, 3, { x: 775, y: 165 }, { x: 455, y: 225, ease: Linear.easeNone }));
    this.animSequence.add(TweenMax.to(this.Vespa2.current, 1, { rotation: -20, x: 348, y: 311, ease: Linear.easeNone }));

    // Vespa 3
    this.animSequence.add(TweenMax.to(this.Vespa2img.current, 0, { alpha: 0 }));
    this.animSequence.add(TweenMax.to(this.Vespa3img.current, 0, { alpha: 1 }));
    this.animSequence.add(TweenMax.fromTo(this.Vespa3.current, 1.5, { x: 388, y: 321 }, { x: 508, y: 401, ease: Linear.easeNone }));
    this.animSequence.add(TweenMax.to(this.Vespa3.current, 0.5, { scale: 0.1, x: 568, y: 531, alpha: 0 }));

    this.animSequence.add(TweenMax.to(this.Target.current, 1, { alpha: 1 }), '-=3');

    // Texten
    CustomWiggle.create("myWiggle", {wiggles:6});
    this.animSequence.add(TweenMax.fromTo(this.Hornstull.current, 0.3, { alpha: 0 }, { alpha: 1 }));
    this.animSequence.add(TweenMax.to(this.Hornstull.current, 0.8, { rotation: -10, ease: "myWiggle", transformOrigin: "center" }));

    this.animSequence.play();
  };

  render() {
    const imageStyle = {
      overflow: 'visible',
      opacity: 0,
    };
    const svgStyle = {
      enableBackground: 'new 0 0 1366 768'
    };

    return (
      <Transition in={this.state.hasMounted} timeout={0} onEntered={this.EnterAnimation}>
        <div className={'map'}>
          <a href={'https://goo.gl/maps/1NLACbmxsKv'} target={'_blank'}>



            <svg version="1.1" id="Karta" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" viewBox="0 0 1366 768" style={svgStyle}>
              <style type="text/css">
                {`.st0{fill:#E2CA2A;stroke:#000000;stroke-miterlimit:10;}`}
              </style>

              <g id="Kartbild">
                <image width="3242" height="1984" id="yUZ7QR_2_"
                       xlinkHref={kartbakgrund} transform="matrix(0.4213 0 0 0.4213 0 0)">
                </image>
              </g>

              <g id="Vespa1" ref={this.Vespa1}>
                <image ref={this.Vespa1img} style={imageStyle} width="178" height="206" id="Vespa1" xlinkHref={Vespa3}
                       transform="matrix(-0.3497 -0.3392 -0.3392 0.3497 0 0)">
                </image>
              </g>

              <g id="Vespa2" ref={this.Vespa2}><image ref={this.Vespa2img} style={imageStyle} width="178" height="206" id="Vespa2" xlinkHref={Vespa2}
                                    transform="matrix(0.5 0 0 0.5 0 0)"></image></g>

              <g id="Vespa3" ref={this.Vespa3}><image ref={this.Vespa3img} style={imageStyle} width="148" height="235" id="Vespa3" xlinkHref={Vespa1}
                                                     transform="matrix(0.5 0 0 0.5 0 0)"></image></g>

              <g id="Target">
                <image ref={this.Target} style={imageStyle} width="72" height="72" id="Layer_1_3_" xlinkHref={Target} transform="matrix(0.5 0 0 0.5 551 523)"></image>
              </g>

              <g id="BarBrooklyn" ref={this.Hornstull}>
                <path className="st0" d="M614.9,553.7c0,0.3-0.3,0.6-0.6,0.6l-6.4,0.7c-0.3,0-0.5-0.2-0.5-0.5l0.1-7.9c0-0.3-0.2-0.5-0.5-0.5l-4.4,0.3
          c-0.3,0-0.5,0.3-0.5,0.6l-0.8,8.6c0,0.3-0.3,0.5-0.6,0.5l-6.6-0.7c-0.3,0-0.5-0.3-0.5-0.6l0.9-26.2c0-0.3,0.3-0.5,0.5-0.5l8-0.1
          c0.3,0,0.5,0.2,0.5,0.5l-0.8,10.6c0,0.3,0.2,0.5,0.5,0.5l4-0.2c0.3,0,0.5-0.3,0.5-0.5l0.1-9.9c0-0.3,0.2-0.5,0.5-0.5l7.2,0.1
          c0.3,0,0.5,0.3,0.5,0.5L614.9,553.7z"/>
                <path className="st0" d="M628.2,555.3c-5.1,0-9.9-3.5-9.9-10c0-6.5,3.6-10.4,9.4-10.4c5.4,0,9.3,3.5,9.3,10.4
          C636.9,551.6,633.2,555.3,628.2,555.3z M627.5,542.3c-1.7,0-2.5,1.4-2.5,3.2c0,1.8,1.2,2.9,2.6,2.9c1.5,0,2.4-1.2,2.4-3.1
          C630,543.5,628.9,542.3,627.5,542.3z"/>
                <path className="st0" d="M651.8,535.3c0,0.7-0.2,5.1-0.3,5.9c0,0.2-0.1,0.3-0.3,0.3c-3.6,0.1-5.1,2.4-5.1,6.5c0,0,0,2.6,0,6.1
          c0,0.3-0.2,0.5-0.5,0.5l-5.7,0.1h0c-0.3,0-0.5-0.2-0.5-0.5l-0.6-18.2v0c0-0.3,0.2-0.5,0.5-0.5h0l5.8,0.3c0.4,0,0.5,0.1,0.5,0.5v0.8
          c0,0.1,0,0.1,0,0.1c0,0,0,0,0.1-0.1c1.2-1.6,3.4-2.2,4.9-2.2c0.4,0,0.7,0,0.9,0.1C651.7,535,651.8,535.1,651.8,535.3L651.8,535.3z"
                />
                <path className="st0" d="M673.8,554.8c0,0.2-0.2,0.3-0.4,0.3c0,0,0,0,0,0l-6.7-0.5c-0.2,0-0.3-0.1-0.3-0.3v0c0.2-1.1,0.2-2.7,0.2-5.3
          c0-6.1-0.2-8.3-2.4-8.3c-1.7,0-2.5,1.1-2.4,3.9l0,9.5c0,0.3-0.2,0.5-0.5,0.5l-6.3-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.5-18.7v0
          c0-0.3,0.2-0.5,0.5-0.5l6.7,0.1c0.3,0,0.5,0.2,0.5,0.5v1c1.1-0.6,2.5-0.9,4.3-0.9c5,0,8.1,2.3,8.1,11.9
          C674,551.5,673.9,553.9,673.8,554.8z"/>
                <path className="st0" d="M693.4,544c-0.2,0.6-0.7,0.9-1.3,0.8l-4.5-0.2c-0.7,0-0.8-0.4-0.8-1c0-1.3-0.7-2.5-1.9-2.5
          c-1.4,0-1.8,0.8-1.8,1.3c0,1.5,1.5,1.9,2.7,2.2c3.1,1,7.4,1.5,7.4,5.8c0,4-4.2,5.6-8.2,5.6c-5.9,0-9.4-2.6-9.4-6
          c0-0.8,0.1-1.3,1-1.3l4.1-0.1c0.9,0,1,0.5,1,1.2c0,0.9,1.1,1.5,2.7,1.5c1.4,0,1.7-0.6,1.7-0.7c0-1.4-3.5-1.8-4.5-2.1
          c-2.5-0.8-5.5-2.3-5.5-6.4c0-4.8,3.1-8,8.2-8c4.7,0,9.1,2.5,9.1,8.5C693.5,543,693.5,543.6,693.4,544z"/>
                <path className="st0" d="M708.7,541.5c0,0.3-0.2,0.5-0.4,0.5l-2.4,0c-0.3,0-0.5,0.2-0.5,0.5l-0.7,12.2c0,0.3-0.2,0.4-0.5,0.4h0
          l-5.9-0.4c-0.2,0-0.4-0.2-0.4-0.5c0,0,0,0,0,0l0.3-11.9v0c0-0.2-0.2-0.4-0.5-0.5h-2c-0.3,0-0.5-0.2-0.5-0.5l-0.3-5.5v0
          c0-0.2,0.2-0.4,0.4-0.4l2.5,0c0.3,0,0.5-0.2,0.5-0.5l0.1-6.2c0-0.3,0.2-0.5,0.5-0.5l6.8,0.1c0.2,0,0.4,0.2,0.4,0.5l-0.3,6.3
          c0,0,0,0,0,0c0,0.3,0.2,0.5,0.4,0.5l2.1,0c0.3,0,0.5,0.2,0.5,0.5L708.7,541.5z"/>
                <path className="st0" d="M729.7,555.1c0,0.3-0.3,0.6-0.6,0.6h0l-5.9-0.2c-0.3,0-0.6-0.3-0.6-0.6v-0.8c-0.9,0.4-1.9,0.7-3.1,0.7
          c-6.8,0-9-4.8-9-12.3c0-2.9,0.4-5.1,0.6-6.1c0-0.2,0.2-0.3,0.4-0.3c0,0,0,0,0,0l6.9,0.5c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0.1
          c-0.2,1.3-0.7,5.3-0.7,7.7c0,3.4,0.9,4.7,2.3,4.7s2.2-0.9,2.3-3.3V536c0-0.3,0.3-0.6,0.6-0.6l6.6-0.3c0,0,0,0,0,0
          c0.3,0,0.5,0.2,0.5,0.5L729.7,555.1z"/>
                <path className="st0" d="M740.3,554.5c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0l-5.8-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.4-25.5c0,0,0,0,0,0
          c0-0.3,0.2-0.5,0.5-0.5l6.8,0.1c0.3,0,0.5,0.2,0.5,0.5L740.3,554.5z"/>
                <path className="st0" d="M751.1,554.5c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0l-5.8-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.4-25.5c0,0,0,0,0,0
          c0-0.3,0.2-0.5,0.5-0.5l6.8,0.1c0.3,0,0.5,0.2,0.5,0.5L751.1,554.5z"/>
                <path className="st0" d="M771.7,544c-0.2,0.6-0.7,0.9-1.3,0.8l-4.5-0.2c-0.7,0-0.8-0.4-0.8-1c0-1.3-0.7-2.5-1.9-2.5
          c-1.4,0-1.8,0.8-1.8,1.3c0,1.5,1.5,1.9,2.7,2.2c3.1,1,7.4,1.5,7.4,5.8c0,4-4.2,5.6-8.2,5.6c-5.9,0-9.4-2.6-9.4-6
          c0-0.8,0.1-1.3,1-1.3l4.1-0.1c0.9,0,1,0.5,1,1.2c0,0.9,1.1,1.5,2.7,1.5c1.4,0,1.7-0.6,1.7-0.7c0-1.4-3.5-1.8-4.5-2.1
          c-2.5-0.8-5.5-2.3-5.5-6.4c0-4.8,3.1-8,8.2-8c4.7,0,9.1,2.5,9.1,8.5C771.8,543,771.8,543.6,771.7,544z"/>
                <path className="st0" d="M807,540.3c-0.1,0.6-0.3,1-0.8,1c0,0,0,0,0,0l-6.2-0.2c-0.4,0-0.7-0.4-0.7-0.6c-0.2-2.2-1.8-4.9-4.9-4.9
          c-1.7,0-2.8,0.9-2.8,2c0,0.9,0.6,1.9,2.1,2.5c4,1.6,12.2,2.8,12.2,8.4c0,4.6-3.6,7.2-10.4,7.2c-7.3,0-12.4-4.6-12.6-9.2
          c0,0,0,0,0,0c0-0.5,0.5-0.7,0.7-0.7l5.8-0.2c0,0,0,0,0,0c0.4,0,0.7,0.4,0.7,0.6c0.4,1.4,1.8,3.3,4.4,3.3c1.1,0,1.9-0.7,1.9-1.5
          c0-1.6-5.3-2.8-6.5-3.3c-3.4-1.2-7.1-3.3-7.1-8.2c0-5.7,5.4-9.1,11.1-9.1c6.6,0,13.1,4.7,13.1,11.7
          C807.1,539.5,807.1,539.9,807,540.3z"/>
                <path className="st0" d="M822.1,541.5c0,0.3-0.2,0.5-0.4,0.5l-2.4,0c-0.3,0-0.5,0.2-0.5,0.5l-0.7,12.2c0,0.3-0.2,0.4-0.5,0.4h0
          l-5.9-0.4c-0.2,0-0.4-0.2-0.4-0.5c0,0,0,0,0,0l0.3-11.9v0c0-0.2-0.2-0.4-0.5-0.5h-2c-0.3,0-0.5-0.2-0.5-0.5l-0.3-5.5v0
          c0-0.2,0.2-0.4,0.4-0.4l2.5,0c0.3,0,0.5-0.2,0.5-0.5l0.1-6.2c0-0.3,0.2-0.5,0.5-0.5l6.8,0.1c0.2,0,0.4,0.2,0.4,0.5l-0.3,6.3
          c0,0,0,0,0,0c0,0.3,0.2,0.5,0.4,0.5l2.1,0c0.3,0,0.5,0.2,0.5,0.5L822.1,541.5z"/>
                <path className="st0" d="M837.2,535.3c0,0.7-0.2,5.1-0.3,5.9c0,0.2-0.1,0.3-0.3,0.3c-3.6,0.1-5.1,2.4-5.1,6.5c0,0,0,2.6,0,6.1
          c0,0.3-0.2,0.5-0.5,0.5l-5.7,0.1h0c-0.3,0-0.5-0.2-0.5-0.5l-0.6-18.2v0c0-0.3,0.2-0.5,0.5-0.5h0l5.8,0.3c0.4,0,0.5,0.1,0.5,0.5v0.8
          c0,0.1,0,0.1,0,0.1c0,0,0,0,0.1-0.1c1.2-1.6,3.4-2.2,4.9-2.2c0.4,0,0.7,0,0.9,0.1C837,535,837.2,535.1,837.2,535.3L837.2,535.3z"/>
                <path className="st0" d="M857.4,554.1c0,0.3-0.3,0.6-0.6,0.6l-5.5,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5l0-1.8c-1,1-2.4,1.6-4.2,1.6
          c-5,0-8.3-3.7-8.3-9.6c0-5.6,4-9.3,8.4-9.3c1.5,0,2.9,0.5,4.1,1.2l0-0.9c0-0.3,0.2-0.5,0.5-0.6l6.3-0.1c0.3,0,0.5,0.2,0.5,0.5
          L857.4,554.1z M850.8,543.8c-0.3-1.1-1.2-1.9-2.4-1.9c-1.6,0-2.7,1.3-2.7,3.2c0,1.8,1.2,3,2.7,3c1,0,2.2-0.9,2.5-2.6V543.8z"/>
                <path className="st0" d="M880.1,554.8c0,0.2-0.2,0.3-0.4,0.3c0,0,0,0,0,0l-6.7-0.5c-0.2,0-0.3-0.1-0.3-0.3v0c0.2-1.1,0.2-2.7,0.2-5.3
          c0-6.1-0.2-8.3-2.4-8.3c-1.7,0-2.5,1.1-2.4,3.9l0,9.5c0,0.3-0.2,0.5-0.5,0.5l-6.3-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.5-18.7v0
          c0-0.3,0.2-0.5,0.5-0.5l6.7,0.1c0.3,0,0.5,0.2,0.5,0.5v1c1.1-0.6,2.5-0.9,4.3-0.9c5,0,8.1,2.3,8.1,11.9
          C880.3,551.5,880.2,553.9,880.1,554.8z"/>
                <path className="st0" d="M902.2,528.3l-0.9,25.8c0,0.3-0.3,0.6-0.6,0.6l-5.5,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5l0-1.8
          c-1,1-2.4,1.6-4.2,1.6c-5,0-8.3-3.7-8.3-9.6c0-5.6,4-9.3,8.4-9.3c1.5,0,3,0.5,4.1,1.2l0.2-8.2c0-0.3,0.3-0.6,0.6-0.6l6.3-0.1
          c0,0,0,0,0,0C902,527.8,902.2,528,902.2,528.3C902.2,528.3,902.2,528.3,902.2,528.3z M894.7,543.6c-0.3-1.1-1.2-1.9-2.4-1.9
          c-1.6,0-2.7,1.3-2.7,3.2c0,1.9,1.2,3,2.7,3c1,0,2.2-0.9,2.5-2.6V543.6z"/>
                <path className="st0" d="M933.9,548.9c0,0.3-0.3,0.4-0.5,0.4c-0.9,0-1.8-0.1-2.6-0.1c0,1.7,0,3.5,0,5.2c0,0.3-0.2,0.5-0.5,0.5l-6.3-0.1
          c-0.3,0-0.4-0.2-0.5-0.5c-0.3-1.6-0.2-3.9-0.3-5.6l-7.9-0.3c-0.3,0-0.7-0.3-0.9-0.6c-0.2-0.5-1.3-2.9-1.3-3.3
          c0-0.3,0.2-0.5,0.4-0.7c1.3-1.5,2.4-3.2,3.6-4.9c2.4-3.5,4.8-7,7.2-10.6c0.1-0.2,0.3-0.5,0.6-0.5l6.3,0.1c0.2,0,0.5,0.2,0.5,0.4
          v0.1c-0.3,4.9-0.7,9.7-0.9,14.6c0.3,0,1.7,0,1.7,0c0.7,0,1.3,0.3,1.3,0.7L933.9,548.9z M920.9,541.2c-0.3,0.4-0.8,0.9-1.1,1.3
          c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c0.9,0.2,2.4,0,3.4,0.1c0-1.7,0-3.5,0-5.2C922.5,538.9,921.7,540.1,920.9,541.2z"/>
                <path className="st0" d="M617,580.5c-0.1,0.6-0.3,1-0.8,1c0,0,0,0,0,0l-6.2-0.2c-0.4,0-0.7-0.4-0.7-0.6c-0.2-2.2-1.8-4.9-4.9-4.9
          c-1.7,0-2.8,0.9-2.8,2c0,0.9,0.6,1.9,2.1,2.5c4,1.6,12.2,2.8,12.2,8.4c0,4.6-3.6,7.2-10.4,7.2c-7.3,0-12.4-4.6-12.6-9.2
          c0,0,0,0,0,0c0-0.5,0.5-0.7,0.7-0.7l5.8-0.2c0,0,0,0,0,0c0.4,0,0.7,0.4,0.7,0.6c0.4,1.4,1.8,3.3,4.4,3.3c1.1,0,1.9-0.7,1.9-1.5
          c0-1.6-5.3-2.8-6.5-3.3c-3.4-1.2-7.1-3.3-7.1-8.2c0-5.7,5.4-9.1,11.1-9.1c6.6,0,13.1,4.7,13.1,11.7C617,579.7,617,580.1,617,580.5z
          "/>
                <path className="st0" d="M628.5,595.5c-5.1,0-9.9-3.5-9.9-10c0-6.5,3.6-10.4,9.4-10.4c5.4,0,9.3,3.5,9.3,10.4
          C637.2,591.8,633.6,595.5,628.5,595.5z M624.3,573.5c-1.4,0-2.5-1-2.5-2.3s1.1-2.3,2.5-2.3c1.4,0,2.5,1,2.5,2.3
          S625.7,573.5,624.3,573.5z M627.8,582.5c-1.7,0-2.5,1.4-2.5,3.2c0,1.8,1.2,2.9,2.6,2.9c1.5,0,2.4-1.2,2.4-3.1
          C630.3,583.7,629.2,582.5,627.8,582.5z M631.3,573.5c-1.4,0-2.5-1-2.5-2.3s1.1-2.3,2.5-2.3c1.4,0,2.5,1,2.5,2.3
          S632.7,573.5,631.3,573.5z"/>
                <path className="st0" d="M659.1,568.5l-0.9,25.8c0,0.3-0.3,0.6-0.6,0.6l-5.5,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5l0-1.8
          c-1,1-2.4,1.6-4.2,1.6c-5,0-8.3-3.7-8.3-9.6c0-5.6,4-9.3,8.4-9.3c1.5,0,3,0.5,4.1,1.2l0.2-8.2c0-0.3,0.3-0.6,0.6-0.6l6.3-0.1
          c0,0,0,0,0,0C658.8,568,659.1,568.2,659.1,568.5C659.1,568.5,659.1,568.5,659.1,568.5z M651.5,583.9c-0.3-1.1-1.2-1.9-2.4-1.9
          c-1.6,0-2.7,1.3-2.7,3.2c0,1.9,1.2,3,2.7,3c1,0,2.2-0.9,2.5-2.6V583.9z"/>
                <path className="st0" d="M679.4,588.5c-0.5,3.1-3.7,7-8.5,7c-5.8,0-10.2-3.4-10.2-9.7c0-6.2,3.6-10.9,8.7-10.9c6.4,0,8.6,5,8.6,10.5
          c0,1-0.5,1.3-1.1,1.3c0,0-0.6,0.1-9.6,0.3c0.4,2.1,1.8,2.9,3.1,2.9c1.2,0,2.2-0.6,2.4-1.3c0.1-0.3,0.3-0.5,0.7-0.5
          c3.5-0.1,5.2-0.2,5.2-0.2h0h0c0.3,0,0.5,0.2,0.5,0.4C679.4,588.4,679.4,588.4,679.4,588.5z M669.6,581.2c-1,0-1.8,0.6-2.1,2.2
          c1.4-0.1,3.4-0.2,3.5-0.2c0.1,0,0.1,0,0.1-0.2C671.1,582.1,670.6,581.2,669.6,581.2z"/>
                <path className="st0" d="M693.9,575.5c0,0.7-0.2,5.1-0.3,5.9c0,0.2-0.1,0.3-0.3,0.3c-3.6,0.1-5.1,2.4-5.1,6.5c0,0,0,2.6,0,6.1
          c0,0.3-0.2,0.5-0.5,0.5l-5.7,0.1h0c-0.3,0-0.5-0.2-0.5-0.5l-0.6-18.2v0c0-0.3,0.2-0.5,0.5-0.5h0l5.8,0.3c0.4,0,0.5,0.1,0.5,0.5v0.8
          c0,0.1,0,0.1,0,0.1c0,0,0,0,0.1-0.1c1.2-1.6,3.4-2.2,4.9-2.2c0.4,0,0.7,0,0.9,0.1C693.7,575.2,693.9,575.3,693.9,575.5L693.9,575.5
          z"/>
                <path className="st0" d="M725.5,595.6c0,0.2-0.2,0.3-0.4,0.3h0l-6.6-0.5c-0.1,0-0.2-0.1-0.2-0.3v0c0.1-1.3,0.3-4,0.3-6.7
          c0-4.9-0.4-6.9-2.2-6.9c-1.9,0-1.6,3.2-1.6,4.4c0,0.6,0.1,1.2,0.1,1.8c0,4.1-0.1,6.4-0.3,7.4c0,0.2-0.2,0.3-0.4,0.3h0l-6.4-0.5
          c-0.1,0-0.2-0.1-0.2-0.3v0c0.1-1.2,0.3-3.9,0.3-6.5c0-5.4-0.2-7.1-2.2-7.1c-1.3,0-1.8,1.1-1.8,3.8c0,0,0,0.1,0,0.1l0,9.5
          c0,0.3-0.2,0.5-0.5,0.5l-6.3-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.4-17.6v0c0-0.3,0.2-0.4,0.5-0.5l6.6-0.7c0,0,0,0,0,0
          c0.2,0,0.4,0.3,0.5,0.5v0.7c0.2-0.2,1.4-1.2,3.3-1.2c2.5,0,4.1,0.7,5.5,2.6c1-0.9,2.6-2.1,5-2.1c5,0,8.4,2.5,8.4,12.2
          C725.8,592.3,725.7,594.6,725.5,595.6z"/>
                <path className="st0" d="M746.6,594.3c0,0.3-0.3,0.6-0.6,0.6l-5.5,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5l0-1.8c-1,1-2.4,1.6-4.2,1.6
          c-5,0-8.3-3.7-8.3-9.6c0-5.6,4-9.3,8.4-9.3c1.5,0,3,0.5,4.1,1.2l0-0.9c0-0.3,0.2-0.5,0.5-0.6l6.3-0.1c0.3,0,0.5,0.2,0.5,0.5
          L746.6,594.3z M739.9,584c-0.3-1.1-1.2-1.9-2.4-1.9c-1.6,0-2.7,1.3-2.7,3.2c0,1.8,1.2,3,2.7,3c1,0,2.2-0.9,2.5-2.6V584z"/>
                <path className="st0" d="M757.2,594.7c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0l-5.8-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.4-25.5c0,0,0,0,0,0
          c0-0.3,0.2-0.5,0.5-0.5l6.8,0.1c0.3,0,0.5,0.2,0.5,0.5L757.2,594.7z"/>
                <path className="st0" d="M789.8,595.6c0,0.2-0.2,0.3-0.4,0.3h0l-6.6-0.5c-0.1,0-0.2-0.1-0.2-0.3v0c0.1-1.3,0.3-4,0.3-6.7
          c0-4.9-0.4-6.9-2.2-6.9c-1.9,0-1.6,3.2-1.6,4.4c0,0.6,0.1,1.2,0.1,1.8c0,4.1-0.1,6.4-0.3,7.4c0,0.2-0.2,0.3-0.4,0.3h0l-6.4-0.5
          c-0.1,0-0.2-0.1-0.2-0.3v0c0.1-1.2,0.3-3.9,0.3-6.5c0-5.4-0.2-7.1-2.2-7.1c-1.3,0-1.8,1.1-1.8,3.8c0,0,0,0.1,0,0.1l0,9.5
          c0,0.3-0.2,0.5-0.5,0.5l-6.3-0.2c-0.3,0-0.5-0.2-0.5-0.5l-0.4-17.6v0c0-0.3,0.2-0.4,0.5-0.5l6.6-0.7c0,0,0,0,0,0
          c0.2,0,0.4,0.3,0.5,0.5v0.7c0.2-0.2,1.4-1.2,3.3-1.2c2.5,0,4.1,0.7,5.5,2.6c1-0.9,2.6-2.1,5-2.1c5,0,8.4,2.5,8.4,12.2
          C790,592.3,789.9,594.6,789.8,595.6z"/>
              </g>
            </svg><br/>
          </a>
        </div>
      </Transition>
    )
  }
}

export default Map;
