import React, { Component } from 'react';
import TopNav from "./TopNav";
import Map from "../map/Map";
import HittaRubrik from '../images/rubrik_hitta.png';
import { Link } from 'react-router-dom';

class Hitta extends Component {
  render() {
    return (
      <div className={'hitta'}>
        <TopNav/>
        <div className={'page-content'}>
          <img src={HittaRubrik} className={'rubrik'} alt={'Hitta'} />
          <div className={'navlinks'}>
            <Link to={'anmalan'}>Anmälan</Link>
            <Link to={'info'}>Info</Link>
          </div>
          <Map />
          <a  className={'googlemapslink'} href={'https://goo.gl/maps/1NLACbmxsKv'} target={'_blank'}>Klicka för att öppna i Google Maps</a>
        </div>
      </div>
    )
  }
}

export default Hitta;
