import React, { Component } from 'react';
import Palookaville from '../images/the_beat_from_palookaville.png';
import BrooklynBrewery from '../images/brooklyn_brewery.svg';
import Posh from '../images/posh_industries.svg';
import {Transition} from "react-transition-group";
import {TweenMax, Linear} from "gsap";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
    }

    this.Posh = React.createRef();
    this.Palooka = React.createRef();
    this.Brooklyn = React.createRef();
  }

  componentDidMount() {
    this.setState(() => ({
      hasMounted: true,
    }));
  }

  componentWillUnmount() {
    TweenMax.killTweensOf(this.Posh.current);
    TweenMax.killTweensOf(this.Palooka.current);
    TweenMax.killTweensOf(this.Brooklyn.current);
  }

  EnterAnimation = () => {
    TweenMax.to(this.Posh.current, 1, { delay: Math.floor(Math.random() * 5), rotation: 360, ease: Linear.easeNone, repeat: -1, repeatDelay: 5 + Math.floor((Math.random() * 10)) });
    TweenMax.to(this.Palooka.current, 1, { delay: Math.floor(Math.random() * 15), rotation: -360, ease: Linear.easeNone, repeat: -1, repeatDelay: 15 + Math.floor((Math.random() * 10)) });
    TweenMax.to(this.Brooklyn.current, 1, { rotation: 360, ease: Linear.easeNone, repeat: -1, repeatDelay: 15 });
  };


  render() {
    return (
      <Transition in={this.state.hasMounted} timeout={0} onEntered={this.EnterAnimation}>
        <div className={'footer'}>
          <div className={'logo'}>
            <a href={'https://www.posh.se'} target={'_blank'}>
              <img src={Posh} alt={'Posh Industries'} ref={this.Posh} />
            </a>
          </div>

          <div className={'logo'}>
            <a href={'https://www.facebook.com/thebeatfrompalookaville/'} target={'_blank'}>
              <img src={Palookaville} alt={'The Beat from Palookaville'} ref={this.Palooka} />
            </a>
          </div>

          <div className={'logo'}>
            <a href={'http://brooklynbrewery.com/'} target={'blank'}>
              <img src={BrooklynBrewery} alt={'Brooklyn Brewery'} ref={this.Brooklyn} />
            </a>
          </div>
        </div>
      </Transition>
    )
  }
}

export default Footer;
