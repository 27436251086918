import React, { Component } from 'react';
import Farre50 from '../images/farre50.svg';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import {Transition} from "react-transition-group";
import {Linear, TweenMax } from "gsap";
import { CustomWiggle } from "../js/CustomWiggle";
import {SplitText} from "../js/SplitText";
import KnappHitta from '../images/knapp_hitta.png';
import KnappAnmalan from '../images/knapp_anmalan.png';
import KnappInfo from '../images/knapp_info.png';
import Nina from '../images/nina.jpg';
import Jenni from '../images/jenni.jpg';
import Close from '../images/close.png';

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
      festJenni: false,
      festNina: false,
    };

    this.Background = React.createRef();
    CustomWiggle.create("myWiggle", {wiggles:6});
    this.SkaLink = React.createRef();
    this.SplitText = null;
    this.BrooklynText = null;
    this.BrooklynThing = React.createRef();

    this.Knapp1 = React.createRef();
    this.Knapp2 = React.createRef();
    this.Knapp3 = React.createRef();

    this.Interval1 = null;
    this.Interval2 = null;
    this.Interval3 = null;
  }

  componentDidMount() {
    this.setState(() => ({
      hasMounted: true,
    }));
  }

  componentWillMount() {
    clearInterval(this.Interval1);
    clearInterval(this.Interval2);
    clearInterval(this.Interval3);

    this.KillAnimations();
    this.setState(() => ({
      hasMounted: false,
    }));
  }

  EnterAnimation = () => {
    TweenMax.to(this.Background.current, 40, { backgroundPosition: "-100% 0", repeat: -1, ease: Linear.easeNone, force3D: true });

    this.BrooklynAnimation();
    this.PalookavilleAnimation();

    // this.Interval1 = setInterval(this.AnimKnapp1, 3000);
    // this.Interval2 = setInterval(this.AnimKnapp2, 5000);
    // this.Interval3 = setInterval(this.AnimKnapp3, 3500);
  };

  AnimKnapp1 = () => {
    if (!this.Knapp1.current) {
      clearInterval(this.Interval1);
      return;
    }

    TweenMax.fromTo(this.Knapp1.current, 0.1, { alpha: 1 }, { alpha: 0.6, yoyo: true, repeat: 11 });
  };

  AnimKnapp2 = () => {
    if (!this.Knapp2.current) {
      clearInterval(this.Interval2);
      return;
    }

    TweenMax.fromTo(this.Knapp2.current, 0.8, { rotation: 0 }, { rotation: -10, ease: "myWiggle", transformOrigin: "center", repeat: 3 });
  };

  AnimKnapp3 = () => {
    if (!this.Knapp3.current) {
      clearInterval(this.Interval3);
      return;
    }

    TweenMax.fromTo(this.Knapp3.current, 0.1, { y: 0 }, { y: 10, repeat: 6, yoyo: true });
  };


  PalookavilleAnimation = () => {
    if (this.SplitText === null) {
      this.SplitText = new SplitText(this.SkaLink.current, { type: 'chars'});
    }

    const duration = 0.05;

    TweenMax.staggerFromTo(this.SplitText.chars, duration, { color: '#9a2301', rotation: 0 }, { rotation: 10, color: '#00000', yoyo: true, repeat: -1, repeatDelay: duration * 2.3, delay: Math.random() }, duration * 2);
  };

  BrooklynAnimation = () => {
    if (this.BrooklynText === null) {
      this.BrooklynText = new SplitText(this.BrooklynThing.current, { type: 'chars'});
    }

    const duration = 0.05;

    TweenMax.staggerFromTo(this.BrooklynText.chars, duration, { color: '#00994c', y: 0 }, { y: -5, color: '#00000', yoyo: true, repeat: -1, repeatDelay: duration * 2.3 }, duration * 2);
  };

  KillAnimations = () => {
    TweenMax.killTweensOf(this.Background.current);
    TweenMax.killTweensOf(this.Knapp1.current);
    TweenMax.killTweensOf(this.Knapp2.current);
    TweenMax.killTweensOf(this.Knapp3.current);
    TweenMax.killTweensOf(this.SplitText);
    TweenMax.killTweensOf(this.BrooklynText);
  };

  OpenFestgeneral = (e) => {
    if (e.currentTarget.href.indexOf('nina') > -1) {
      this.setState(() => ({
        festNina: true,
      }));
    } else {
      this.setState(() => ({
        festJenni: true,
      }));
    }
  };

  CloseFestgeneral = () => {
    this.setState(() => ({
      festNina: false,
      festJenni: false,
    }));
  };

  render() {
    return (
      <Transition in={this.state.hasMounted} timeout={0} onEntered={this.EnterAnimation} onExiting={this.KillAnimations}>
      <div className={'startsida'}>
          <div className={'banner'}>
            <div className={'chequered'} ref={this.Background}></div>
            <div className={'partyinfo'}>
              <div className={'age'}>Farre fyller 50!!</div>
              <div className={'logo'}><img src={Farre50} alt={'Farre 50'} /></div>
              <div className={'come'}>Kom på fest!!</div>
            </div>
          </div>

          <div className={'navigation'}>
            <div className={'button'} id={'btn3'}>
              <Link to={'info'}><img src={KnappInfo} alt={'Info'} ref={this.Knapp1} onMouseOver={this.AnimKnapp1} /></Link>
            </div>
            <div className={'button'} id={'btn2'}>
              <Link to={'anmalan'}><img src={KnappAnmalan} alt={'Anmälan'} ref={this.Knapp2} onMouseOver={this.AnimKnapp2} /></Link>
            </div>
            <div className={'button'} id={'btn1'}>
              <Link to={'hitta'}><img src={KnappHitta} alt={'Hitta'} ref={this.Knapp3} onMouseOver={this.AnimKnapp3} /></Link>
            </div>
          </div>

            <div className={'desc'}>
              <div>På årets födelsedagsfest den <span className={'red'}>25e maj</span> kör vi temat&nbsp;
                <Link to={'ska-musik'}>Ska-musik</Link> som är Farres absoluta favoritmusik sedan tonåren.</div><br/>

              <div>Som grädden på moset och bacon på ägget har vi dessutom lyckats boka Farres favoritband bland svenska Ska-band: <div><span className={'palookaville'} ref={this.SkaLink}>The Beat from Palookaville.</span></div></div><br/>

              <div>Och detta på inget mindre än ett av Farres favoritställen: Bar Brooklyn, som serverar Farres favorit-öl från <div><span className={'brooklyn'} ref={this.BrooklynThing}>Brooklyn Brewery</span>.</div></div>

              <p className={'fullpott'}>Full pott!</p>

              <p>Varmt välkommen, önskar<br/>
                Festgeneral <a onClick={this.OpenFestgeneral} href={'#nina'}>Nina</a> &<br/> Festgeneral <a onClick={this.OpenFestgeneral} href={'#Jenni'}>Jenni</a></p>
            </div>

            <Footer/>

        {this.state.festNina &&
        <div className={`festgeneral ${this.state.festNina ? 'visible' : 'hidden'}`}>
          <div className={'close'}><img src={Close} alt={'Stäng'} onClick={this.CloseFestgeneral}/></div>
          <div className={'bild'}><img src={Nina} alt={'Nina'} /></div>
          <div className={'details'}>Nina<br/><a href={'mailto:nina@posh.se'}>nina@posh.se</a><br/><a href={'tel:0707970103'}>070-797 01 03</a></div>
        </div>}

        {this.state.festJenni &&
        <div className={`festgeneral ${this.state.festJenni ? 'visible' : 'hidden'}`}>
          <div className={'close'}><img src={Close} alt={'Stäng'} onClick={this.CloseFestgeneral}/></div>
          <div className={'bild'}><img src={Jenni} alt={'Jenni'} /></div>
          <div className={'details'}>Jenni<br/><a href={'mailto:jenni@posh.se'}>jenni@posh.se</a><br/><a href={'tel:0703962175'}>070-396 21 75</a></div>
        </div>}
      </div>
      </Transition>
    );
  }
}

export default Start;
