import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup } from 'react-transition-group';
import Start from "./pages/Start";
import Hitta from "./pages/Hitta";
import Anmalan from "./pages/Anmalan";
import Presenter from "./pages/Presenter";
import Ska from "./pages/Ska";

class App extends Component {

  render() {
    return (
      <Router>
        <div className={'farre'}>
          <TransitionGroup component={null} appear={true}>
            <Switch>
              <Route path={'/'} exact component={Start} />
              <Route path={'/hitta'} exact component={Hitta}/>
              <Route path={'/info'} exact component={Presenter} />
              <Route path={'/anmalan'} exact component={Anmalan} />
              <Route path={'/ska-musik'} exact component={Ska} />
            </Switch>
          </TransitionGroup>
        </div>
      </Router>
    );
  }
}

export default App;
