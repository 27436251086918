import React, { Component } from 'react';
import TopNav from "./TopNav";
import Trivia from '../images/trivia.jpg';
import Musik from '../images/musikexempel.jpg';
import {Transition} from "react-transition-group";
import RubrikSka from '../images/rubrik_ska.png';
import Close from '../images/close.png';

class Ska extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
      videoOpen: false,
      whichVideo: '',
    };
  }

  componentDidMount() {
    this.setState(() => ({
      hasMounted: true,
    }));
  }

  EnterAnimation = () => {
  };

  ShowVideo = (e) => {
    const classList = e.currentTarget.classList;
    let whichVideo = '';

    if (classList.contains('madness')) {
      whichVideo = 'madness';
    }

    if (classList.contains('specials')) {
      whichVideo = 'specials';
    }

    if (classList.contains('beat')) {
      whichVideo = 'beat';
    }

    if (classList.contains('manners')) {
      whichVideo = 'manners';
    }

    if (classList.contains('buster')) {
      whichVideo = 'buster';
    }

    this.setState(() => ({
      videoOpen: true,
      whichVideo,
    }));
  };

  CloseVideoWindow = () => {
    this.setState(() => ({
      videoOpen: false,
      whichVideo: '',
    }));
  };

  render() {
    return (
      <Transition in={this.state.hasMounted} timeout={0} onEntered={this.EnterAnimation}>
        <div className={'skamusik'}>
          <TopNav/>

          <div className={'page-content'}>
            <img src={RubrikSka} alt={'Ska'} className={'rubrik'} />

            <div className={'split'}>

              <div className={'desc'}>
                <p>Ska är en musikgenre som har sitt ursprung i det sena 1950-talets
                Jamaica.<br/>
                Ska-musiken är föregångare till musikstilarna rocksteady och reggae.
                Ska kombinerade karibisk mentomusik och calypso med amerikansk
                  jazz och rythm and blues.</p>

                <p>Den karaktäriseras av en snabb rytm i 4/4-takt som markeras av en
                  tydlig baktakt.</p>

                <p>Under tidigt 1960-tal dominerade ska-musiken den jamaicanska
                musikscenen, och den var även populär bland brittiska mods.<br/>
                  Senare blev den populär bland många skinheads.</p>

                <p>Musikhistoriker delar oftast in ska-musikens historia i tre perioder:<br/><br/>
                1. Den jamaicanska originalscenen under 1960-talet (Första vågen)<br/><br/>
                2. Det engelska 2 Tone-återuppväckandet av ska under 1970-talet
                (Andra vågen)<br/><br/>
                3. Tredje vågens skarörelse, som började under 1980-talet och steg<br/>
                  i popularitet i USA under 1990-talet.</p>

              </div>

              <div className={'tavlor'}>
                Klicka på artisterna för fantastiska<br/> YouTube-videos!
                <div className={'musikexempel'}>
                  <div className={'bg'}><img src={Musik} alt={'Provlyssna dessa band'} useMap={'#musicmap'} /></div>
                  <div className={'musiclink madness'} onClick={this.ShowVideo}></div>
                  <div className={'musiclink specials'} onClick={this.ShowVideo}></div>
                  <div className={'musiclink beat'} onClick={this.ShowVideo}></div>
                  <div className={'musiclink manners'} onClick={this.ShowVideo}></div>
                  <div className={'musiclink buster'} onClick={this.ShowVideo}></div>
                </div>
                
                <img src={Trivia} alt={'Trivia'} />
              </div>
            </div>
          </div>

          <div className={`videoplayer ${this.state.videoOpen ? 'visible' : 'hidden'}`}>
            <div className={'video'}>
              {this.state.whichVideo === 'madness' &&
              <iframe title={'madness'} width="100%" height="100%" src="https://www.youtube.com/embed/rXuvdeEC5y8" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe> }

              {this.state.whichVideo === 'specials' &&
              <iframe title={'specials'} width="100%" height="100%" src="https://www.youtube.com/embed/cntvEDbagAw" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>}

              {this.state.whichVideo === 'beat' &&
              <iframe title={'beat'} width="100%" height="100%" src="https://www.youtube.com/embed/SHWrmIzgB5A" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>}

              {this.state.whichVideo === 'manners' &&
              <iframe title={'manners'} width="100%" height="100%" src="https://www.youtube.com/embed/Ha4NHacX2kE" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>}

              {this.state.whichVideo === 'buster' &&
              <iframe title={'buster'} width="100%" height="100%" src="https://www.youtube.com/embed/5ukZmiFKzog" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>}

              <div className={'close'} onClick={this.CloseVideoWindow}>
                <img src={Close} alt={'Stäng'} />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    )
  }
}

export default Ska;
